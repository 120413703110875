*, body {
  font-family: 'Gilda Display', serif;
  font-size: 14px;
}

h1, h2, h3, h4 {
  padding: 0; margin: 0;
}

h1 {
  margin-top: 2em;
  font-size: 22px;
  line-height: 1.2em;
  margin-bottom: 1.75em;
}

h2 {
  font-size: 22px;
  line-height: 1.2em;
  margin-bottom: 1.75em;
}

h3 {
  text-transform: uppercase;
  font-size: 16px;
}

::selection {
  background-color: #FFE4E1;
  color: #000;
}

a:visited, a:link {
  font-size: 16px;
  color: #999;
}
a:hover, a:active {
  color: #555;
  text-decoration: underline;
}

p, ul, li {
  font-size: 16px;
}

header {
  margin-top: 8.125em;
  margin-bottom: 4.5em;

  img.logo {
    width: 50%;
  }

  ul.menu {
    margin-top: 1.4em;
    li {
      display: inline-block;
      margin-right: 2.5em;
      color: #999;

      &.active, &:hover {
        padding-bottom: .5em;
        border-bottom: 1px solid #999;
        color: #000;
      }

      a {
        font-size: 16px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

section {
  margin-top: 3em;

  ul {
    padding: 1.1em;
    margin: 0;
  }
  
  .social-icons {
    a {
      text-decoration: none;
      cursor: pointer;

      span {
        font-size: 22px;
        padding: 0.25em;
        background-color: #eeeeee;
      }
    }
  }
}


div.sub-intro-frame {
  width: 400px;
  height: 200px;
  background-color: #fff;
  margin-bottom: 3em;
}

p.intro-text {
  width: 400px;
}

img.juliadresel {
  width: 100%;
}

p {
  font-size: 16px;
  margin-bottom: 2em;
}

.projects-grid {
  margin-bottom: 2em;

  .project-item {
    margin-right: 1em;
    width: 350px;

    img {
      width: 100%;
      margin-bottom: .5em;
    }
    span.leistung {
      color: #999;
      font-size: 16px;
    }
  }
}

footer {
  p {
    font-size: 22px;
    a:visited, a:link, a:hover, a:active {
      font-size: 22px;
    }

    &.footer-bright {
      text-align: right;
      color: #dedede;
      font-size: 14px;

      a:visited, a:link, a:hover, a:active {
        color: #cecece;
        font-size: 14px;
      }
    }
  }
}



@media only screen and (max-width:1199px){
  header {

  }
}

@media only screen and (max-width:899px){
  footer {

  }
}

/** Mobile **/
@media only screen and (max-width:625px){
  .grid-container {
    .grid-line {
      .col-third-width {
        display: block;
        width: 100%;
      }
      .col-half-width {
        clear: both;
        width: 100%;
      }
    }
  }


  div.sub-intro-frame {
    width: 100%;
    height: 200px;
    background-color: #eee;
    margin-bottom: 3em;
    display: none;
  }
  p.intro-text {
    width: 100%;
  }

  header {
    margin-top: 2em;

    ul.menu {
      display: block;
      padding-left: 0;
      margin-top: 2em;
    }
  }

  h1, h2 {
   margin-top: 2em;
  }

  section {
    margin-bottom: 2em;
  }

  #section-leistungen {
    background-color: #eee;

    &::before {
      content: " ";
      display: block;
      background-color: #eee;
      width: 100%;
      height: 28px;
      position: absolute;
      left: -0px;
      right: 0;
      top: -15px;
      transform: rotate(-2deg);
    }

    &::after {
      content: " ";
      display: block;
      background-color: #eee;
      width: 100%;
      height: 22px;
      position: absolute;
      left: -10px;
      right: 0;
      bottom: -5px;
      transform: rotate(-2deg);
    }
  }

  footer {
    .col-third-width {
      width: 100%;
    }
  }
}