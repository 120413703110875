header {
  opacity: 0;
  transform: scale(.9);
  transition: opacity ease-in-out .5s, transform ease-in-out .7s;

  &.loaded {
    transform: scale(1);
    opacity: 1;
  }
}

section .grid-line .col-half-width, section .grid-line .col-full-width, section .grid-line .col-third-width {
  opacity: 0;
  transform: translateY(2em);
  transition: opacity ease-in-out .7s, transform ease-in-out .6s;

  &.loaded {
    transform: translateY(0);
    opacity: 1;
  }
}

footer .grid-line .col-third-width {
  opacity: 0;
  transition: opacity ease-in-out .4s, transform ease-in-out .3s;
  transform: translateY(2em);

  &.loaded {
    opacity: 1;
    transform: translateY(0);
  }
}